:root {
  --colors-tomato-50: #fef6f2;
  --colors-tomato-100: #fdece4;
  --colors-tomato-200: #fbd2bf;
  --colors-tomato-300: #f7b99a;
  --colors-tomato-400: #f4a17b;
  --colors-tomato-500: #ef855a;
  --colors-tomato-600: #e85526;
  --colors-tomato-700: #bc3d19;
  --colors-tomato-800: #9a3412;
  --colors-tomato-900: #7c2d12;
  --colors-tomato-950: #431407;
  --colors-neutral-50: #fafafa;
  --colors-neutral-100: #f5f5f5;
  --colors-neutral-200: #e9e9e7;
  --colors-neutral-300: #d4d4d4;
  --colors-neutral-400: #a7a8aa;
  --colors-neutral-500: #727272;
  --colors-neutral-600: #525252;
  --colors-neutral-700: #404040;
  --colors-neutral-800: #2b2b2b;
  --colors-neutral-900: #171717;
  --colors-neutral-950: #0a0a0a;
  --colors-hudson-navy-50: #f2fafc;
  --colors-hudson-navy-100: #ecf5f8;
  --colors-hudson-navy-200: #d5e6ee;
  --colors-hudson-navy-300: #a3cadb;
  --colors-hudson-navy-400: #80bfd6;
  --colors-hudson-navy-500: #55aac5;
  --colors-hudson-navy-600: #008eb1;
  --colors-hudson-navy-700: #00728d;
  --colors-hudson-navy-800: #055877;
  --colors-hudson-navy-900: #0d3f5e;
  --colors-hudson-navy-950: #0a2c41;
  --colors-olive-50: #faf9f2;
  --colors-olive-100: #f2efde;
  --colors-olive-200: #e7e2c4;
  --colors-olive-300: #d9d3a4;
  --colors-olive-400: #cfc78f;
  --colors-olive-500: #b4ac6e;
  --colors-olive-600: #98904d;
  --colors-olive-700: #7c732b;
  --colors-olive-800: #5e550d;
  --colors-olive-900: #47410c;
  --colors-olive-950: #302b01;
  --colors-plum-50: #f9f6f7;
  --colors-plum-100: #f0e7ea;
  --colors-plum-200: #e5d4da;
  --colors-plum-300: #ceaeba;
  --colors-plum-400: #bd91a4;
  --colors-plum-500: #ac748d;
  --colors-plum-600: #94456d;
  --colors-plum-700: #863762;
  --colors-plum-800: #752755;
  --colors-plum-900: #54223d;
  --colors-plum-950: #3b1e25;
  --colors-base-black: #2b2b2b;
  --colors-base-white: #ffffff;
  --colors-base-focus: #3481fe;
  --colors-dialog-confirmation-dark: #008044;
  --colors-dialog-confirmation-mid: #bbe0c6;
  --colors-dialog-confirmation-light: #eaf6ee;
  --colors-dialog-error-dark: #bc3d19;
  --colors-dialog-error-mid: #f7b99a;
  --colors-dialog-error-light: #fdece4;
  --colors-dialog-information-dark: #1c6ef2;
  --colors-dialog-information-mid: #d2e2fc;
  --colors-dialog-information-light: #edf3fe;
  --colors-dialog-warning-dark: #752755;
  --colors-dialog-warning-mid: #ceaeba;
  --colors-dialog-warning-light: #f0e7ea;
  --colors-accessibility-ring: #3481fe;
  --font-families-lyon: 'Lyon Display';
  --font-families-effra: Effra;
  --spacing-1: 4px;
  --spacing-2: 8px;
  --spacing-3: 12px;
  --spacing-4: 16px;
  --spacing-5: 20px;
  --spacing-6: 24px;
  --spacing-7: 32px;
  --spacing-8: 40px;
  --spacing-9: 48px;
  --spacing-10: 64px;
  --spacing-11: 80px;
  --spacing-12: 96px;
  --spacing-13: 100px;
  --spacing-14: 104px;
  --spacing-15: 128px;
  --spacing-none: 0px;
  --font-weights-regular: 400;
  --font-weights-bold: 700;
  --line-heights-130: 1.3;
  --line-heights-150: 1.5;
  --border-radius-none: 0px;
  --border-radius-sm: 4px;
  --border-radius-md: 8px;
  --border-radius-lg: 12px;
  --border-radius-xl: 40px;
  --border-radius-2xl: 72px;
  --border-radius-full: 9999px;
  --border-widths-1: 1px;
  --border-widths-2: 2px;
  --border-widths-4: 4px;
  --border-widths-none: 0px;
  --font-sizes-xs: 12px;
  --font-sizes-sm: 14px;
  --font-sizes-base: 16px;
  --font-sizes-lg: 18px;
  --font-sizes-xl: 19px;
  --font-sizes-2xl: 20px;
  --font-sizes-3xl: 22px;
  --font-sizes-4xl: 24px;
  --font-sizes-5xl: 26px;
  --font-sizes-6xl: 28px;
  --font-sizes-7xl: 30px;
  --font-sizes-8xl: 32px;
  --font-sizes-9xl: 34px;
  --font-sizes-10xl: 36px;
  --font-sizes-11xl: 38px;
  --font-sizes-12xl: 48px;
  --font-sizes-13xl: 52px;
  --font-sizes-14xl: 68px;
  --opacity-25: 0.25;
  --opacity-50: 0.5;
  --opacity-75: 0.75;
  --opacity-100: 1;
  --opacity-none: 0;
  --shadow-sm: 0 1px 2px 0 rgba(0, 0, 0, 0.05);
  --shadow-base: 0 1px 3px 0 rgba(0, 0, 0, 0.1), 0 1px 2px 0 rgba(0, 0, 0, 0.06);
  --shadow-md: 0 4px 6px -1px rgba(0, 0, 0, 0.1),
    0 2px 4px -1px rgba(0, 0, 0, 0.06);
  --shadow-lg: 0 10px 15px -3px rgba(0, 0, 0, 0.1),
    0 4px 6px -2px rgba(0, 0, 0, 0.05);
  --shadow-xl: 0 20px 25px -5px rgba(0, 0, 0, 0.1),
    0 10px 10px -5px rgba(0, 0, 0, 0.04);
  --shadow-inner: inset 0 2px 4px 0 rgba(0, 0, 0, 0.06);
  --shadow-ring: 0 0 8px 0 #3481fe;
  --blur-none: 0px;
  --blur-base: 12px;
  --text-decoration-link: underline;
  --support-styles-cta-tomato: 700 19px/1.5 Effra;
  --support-styles-cta-tomato-link: 700 19px/1.5 Effra;
  --colors-dialog-confirmation: #008044;
  --colors-dialog-warning: #752755;
  --colors-dialog-disabled: #a7a8aa;
  --colors-background-default: #ffffff;
  --colors-background-muted: #f2fafc;
  --colors-background-popover: #ffffff;
  --colors-background-primary: #0d3f5e;
  --colors-background-secondary: #e85526;
  --colors-background-accent: #faf9f2;
  --colors-background-destructive: #bc3d19;
  --colors-foreground-default: #0d3f5e;
  --colors-foreground-muted: #2b2b2b;
  --colors-foreground-popover: #0d3f5e;
  --colors-foreground-primary: #ffffff;
  --colors-foreground-secondary: #ffffff;
  --colors-foreground-accent: #0d3f5e;
  --colors-foreground-destructive: #ffffff;
  --colors-card-foreground: #0d3f5e;
  --colors-card-bg-primary: #ffffff;
  --colors-card-bg-secondary: #faf9f2;
  --colors-card-bg-tertiary: #e7e2c4;
  --colors-card-bg-quaternary: #cfc78f;
  --colors-card-bg-quinary: #fdece4;
  --colors-card-bg-senary: #f7b99a;
  --colors-card-bg-septenary: #ecf5f8;
  --colors-card-bg-octonary: #a3cadb;
  --colors-card-bg-nonary: #f0e7ea;
  --colors-card-bg-denary: #ceaeba;
  --colors-card-bg-duodenary: #f2efde;
  --colors-form-border: #0d3f5e;
  --colors-form-input: #727272;
  --colors-form-border-secondary: #cfc78f;
  --colors-form-hover: #00728d;
  --colors-form-border-tertiary: #5e550d;
  --colors-form-border-quaternary: #e9e9e7;
  --colors-form-disabled: #a7a8aa;
  --colors-button-primary-default: #0d3f5e;
  --colors-button-primary-hover: #00728d;
  --colors-button-primary-pressed: #0d3f5e;
  --colors-button-primary-focused: #0a2c41;
  --colors-button-primary-disabled: #a7a8aa;
  --colors-button-primary-overlay: #ffffff;
  --colors-button-primary-overlay-hover: #f2efde;
  --colors-button-secondary-default: #e85526;
  --colors-button-secondary-hover: #e85526;
  --colors-button-secondary-pressed: #bc3d19;
  --colors-button-secondary-focused: #e85526;
  --colors-button-secondary-disabled: #a7a8aa;
  --box-shadow-shadow-sm: 0 1px 2px 0 rgba(0, 0, 0, 0.05);
  --box-shadow-shadow-lg: 0 10px 15px -3px rgba(0, 0, 0, 0.1),
    0 4px 6px -2px rgba(0, 0, 0, 0.05);
  --body-lg-regular: 400 18px/1.5 Effra;
  --body-lg-inline-link: 400 18px/1.5 Effra;
  --body-lg-bold-link: 700 18px/1.5 Effra;
  --body-lg-bold: 700 18px/1.5 Effra;
  --body-md-regular: 400 16px/1.5 Effra;
  --body-md-inline-link: 400 16px/1.5 Effra;
  --body-md-bold-link: 700 16px/1.5 Effra;
  --body-md-bold: 700 16px/1.5 Effra;
  --body-sm-regular: 400 14px/1.5 Effra;
  --body-sm-inline-link: 400 14px/1.5 Effra;
  --body-sm-bold-link: 700 14px/1.5 Effra;
  --body-sm-bold: 700 14px/1.5 Effra;
  --body-xs-regular: 400 12px/1.5 Effra;
  --body-xs-inline-link: 400 12px/1.5 Effra;
  --body-xs-bold-link: 700 12px/1.5 Effra;
  --body-xs-bold: 700 12px/1.5 Effra;
  --body-xl-regular: 400 20px/1.5 Effra;
  --body-xl-bold: 700 20px/1.5 Effra;
  --body-xl-inline-link: 400 20px/1.5 Effra;
  --body-xl-bold-link: 700 20px/1.5 Effra;
}
